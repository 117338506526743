var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2gy02Qd3PglT1sXRobJKa"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { replayIntegration } from "@sentry/browser";
import * as Sentry from "@sentry/nextjs";
import { sentryBaseOptions } from "src/integrations/sentry";
import { getConfig } from "./config";

const Config = getConfig();

// Initialize Sentry on app mount for client
const sentryOptions = sentryBaseOptions(
	Config.ENV_NAME,
	Config.SENTRY_DSN,
	Config.SENTRY_ENABLED,
	Config.SENTRY_SAMPLE_RATE,
);

Sentry.init({
	// Enable browser tracing for client-side sentry
	replaysSessionSampleRate: 0,
	replaysOnErrorSampleRate: Config.SENTRY_REPLAY_SAMPLE_RATE,
	integrations: [
		replayIntegration()
	],
	// Ignore network errors tipically caused by the user's network on iOS devices
	ignoreErrors: [
		"TypeError: Failed to fetch",
		"TypeError: NetworkError when attempting to fetch resource.",
		"TypeError: cancelled",
	],
	...sentryOptions,
});
